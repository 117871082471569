<template>
  <div :class="$style.container" :style="`background:${backgroundColor}`">
    <header :class="$style.header">
      <img :src="headerBackground" alt="" />
    </header>
    <main :class="$style.main">
      <slot />
    </main>
    <footer :class="$style.footer">
      <img v-if="footerBackground" :src="footerBackground" />
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
   computed: {
    ...mapState({
      background: (state) => state.application.properties.background,
      backgroundColor: (state) => state.application.properties.backgroundColor,
      headerBackground: (state) => state.application.properties.headerBackground,
      footerBackground: (state) => state.application.properties.footerBackground,
    }),
  },
};
</script>

<style lang="less" module>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    width: 100%;
    // overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .footer {
    width: 100%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>