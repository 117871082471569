export default {

    async getApplicationPages(application, page) {
      const result = await window.axios({
        url: `/application/${application.id}/page`,
        method: 'get',
        params: {
          ...page,
        }
      })
      return result
    },
}