<template>
  <div v-html="content"></div>
</template>

<script>
import Mustache from "mustache";
export default {
  name: "Showcase",
  props: {
    properties: Object,
    data: Object,
  },
  computed: {
    content() {
      return Mustache.render(this.data.content, {
        ...this.$store.state,
        ...this.data,
      });
    },
  },
  data() {
    return {
    
    };
  },
};
</script>

<style>
</style>