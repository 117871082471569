<template>
  <Xlayout>
    <Xstep :step="3" />
    <van-row type="flex" justify="center">
      <van-col :class="$style.checked" span="6">
        <van-icon name="checked" :color="primaryColor" size="88" />
      </van-col>
    </van-row>
    <van-row type="flex" justify="center">
      <van-col :class="$style.checkedText" span="16">
        <h6>{{ mainClues }}</h6>
      </van-col>
    </van-row>

    <van-row type="flex" justify="center">
      <van-col :class="$style.invitation" span="18">
        <div v-if="page.id">
          <template v-for="pageComponent of page.components">
            <showcase
              v-show="pageComponent.component.key === 'Showcase'"
              :properties="pageComponent.properties"
              :data="pageComponent.data"
              :key="pageComponent.id"
            ></showcase>
          </template>
        </div>
        <div v-else>
          <p>尊敬的 {{ visitRecord.properties.name }} 您好：</p>
          <p>{{ application.properties.deputyClues }}</p>
          <p>参观时间：</p>
          <p>
            {{
              `${new Date(exhibition.startDateTime).format(
                "yyyy年MM月dd日"
              )}-${new Date(exhibition.endDateTime).format("dd日")}`
            }}
          </p>
          <p>展会地点：</p>
          <p>{{ exhibition.location }}</p>
        </div>
      </van-col>
    </van-row>

    <!-- <van-row :class="$style.invitationCardInfo" type="flex" justify="center">
      <van-col span="18">
        <p>我的胸卡</p>
        <p>姓名：{{ visitRecord.properties.name }}</p>
        <p>单位: {{ visitRecord.properties.corporation }}</p>
        <p>卡号： {{ visitRecord.id }}</p>
      </van-col>
    </van-row> -->
    <div :class="$style.invitationCard">
      <h6>{{ visitRecord.properties.name }}</h6>
      <p>
        {{
          visitRecord.properties.corporation ||
          visitRecord.properties.organization
        }}
      </p>

      <!-- <div id="qrcode" :class="$style.invitationCardQrcode"></div> -->
      <!-- <img src="../assets/card-border@2x.png" alt="" srcset="" /> -->
      <!-- <vue-qr :margin="0" :text="'111'" :logoScale="0.2" :size="88"></vue-qr> -->
      <vue-qrcode
        :margin="2"
        :width="300"
        :value="visitRecord.cardNumber || visitRecord.id"
      />
      <!-- <p style="text-align: center;">{{visitRecord.cardNumber || visitRecord.id}}</p> -->
      <div :class="$style.reminder">
        <p>提示：凭个人身份证原件，刷闸机人脸比对入场！。</p>
      </div>
    </div>
    <!-- <van-row type="flex" justify="center">
      <van-col :class="$style.reminder" span="22">
        <p>
          友情提示：根据国务院发布的“所有参观/参会人员必须实名制、一人一码”等新冠防疫要求，请您务必携带好本人身份证莅临现场刷证入场。感谢您的理解与配合！
        </p>
      </van-col>
    </van-row> -->

    <van-row
      type="flex"
      justify="center"
      v-if="application.properties.shareUrl"
    >
      <van-col :class="$style.more" span="16" @click="handleClickMore">
        <img
          v-if="application.properties.shareLogo"
          :src="application.properties.shareLogo"
        />
        <p v-else>点击了解更多</p>
      </van-col>
    </van-row>
  </Xlayout>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Xlayout from "../components/layout.vue";
import Xstep from "../components/step.vue";
// import VueQr from "vue-qr";
import VueQrcode from "vue-qrcode";

import Showcase from "../components/showcase";

// import permission from "../router/permission";

import ApplicationPageService from "../service/application-page";

export default {
  components: { Xlayout, Xstep, VueQrcode, Showcase },
  computed: {
    ...mapState({
      isWeChart: (state) => state.isWeChart,
      primaryColor: (state) => state.application.properties.primaryColor,
      backgroundColor: (state) => state.application.properties.backgroundColor,
      exhibition: (state) => state.exhibition,
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      visitRecord: (state) => state.visitRecord,
      businessCard: (state) => state.businessCard,
      mainClues: (state) => state.application.properties.mainClues,
      deputyClues: (state) => state.application.properties.deputyClues,
    }),
  },
  data() {
    return {
      page: {},
    };
  },
  methods: {
    ...mapActions({
      getVisitRecord: "getVisitRecord",
    }),
    handleClickMore: function () {
      if (this.application.properties.shareUrl) {
        window.location.href = this.application.properties.shareUrl;
      }
    },
  },
  async mounted() {
    if (!this.visitRecord || !this.visitRecord.id) {
      this.$router.push({ path: "/" });
    }

    const pages = await ApplicationPageService.getApplicationPages(
      this.application,
      {
        path: this.$route.path,
      }
    );

    console.log(pages);

    if (pages && pages.length > 0) {
      this.page = pages[0];
    }

    // try {
    //   await this.getVisitRecord();

    //   permission.checkVisitRecord(
    //     this.visitRecord,
    //     () => {
    //       //未验证手机号
    //       this.$router.push({ path: "/verification" });
    //     },
    //     () => {
    //       // 未完善信息
    //       this.$router.push({ path: "/info" });
    //     },
    //     () => {
    //       //未填写问卷
    //       this.$router.push({ path: "/questionnaire" });
    //     },
    //     () => {
    //       //预登记完成
    //       return;
    //     }
    //   );
    // } catch (error) {
    //   console.log(error);
    //   this.$router.push({ path: "/" });
    // }

    // 设置参数方式
    // var qrcode = new QRCode(document.getElementById("qrcode"), {
    //   text: this.visitRecord.id,
    //   width: 266,
    //   height: 266,
    //   colorDark: "#000000",
    //   colorLight: "#ffffff",
    //   correctLevel: QRCode.CorrectLevel.H,
    // });

    // // 使用 API
    // qrcode.clear();
    // qrcode.makeCode("new content");
  },
};
</script>

<style lang="less" module>
.checked {
  margin-top: 1rem;
}
.checkedText {
  text-align: center;
  h6 {
    margin: 0;
    font-size: 0.5rem;
  }
}
.invitation {
  p {
    font-size: 0.38rem;
  }
}

.more {
  text-align: center;
  img {
    width: 100%;
  }
  p {
    margin: 0;
    font-size: 0.38rem;
  }
}

.invitationCardInfo {
  border: 1px;
  p {
    font-size: 0.26rem;
  }
}
.invitationCard {
  margin: 18px;
  padding: 1rem;
  height: 426px;
  background-image: url("../assets/card-border@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;

  h6 {
    margin: 0 0 0.38rem 0;
    font-size: 0.46rem;
  }
  p {
    margin: 0;
    font-size: 0.38rem;
  }
  img {
    width: 100%;
  }
}
.invitationCardQrcode {
  position: relative;
  // left:32px;
  // top: 32px;
}
.reminder {
  position: relative;
  top: 40px;
  padding: 4px;
  background: #ffebdf;
  color: #ff720a;
  border-radius: 10px;
  p {
    margin: 0;
    padding-left: 6px;
    font-size: 0.3rem;
  }
}
</style>
