<template>
  <div>
    <van-row type="flex" justify="space-between" :class="$style.container">
      <van-col span="4">
        <img v-if="step < 1" src="../assets/fail.png" />
        <img v-if="step === 1" src="../assets/step2.png" />
        <img v-if="step > 1" src="../assets/success.png" />
        <p>个人信息</p>
      </van-col>
      <van-col span="3" v-if="questionnaireId !== ''">
        <div :class="$style.line"></div>
      </van-col>
      <van-col span="4" v-if="questionnaireId !== ''">
        <img v-if="step < 2" src="../assets/fail.png" />
        <img v-if="step === 2" src="../assets/step2.png" />
        <img v-if="step > 2" src="../assets/success.png" />
        <p>填写问卷</p>
      </van-col>
      <van-col span="3">
        <div :class="$style.line"></div>
      </van-col>
      <van-col span="4">
        <img v-if="step >= 3" src="../assets/success.png" />
        <img v-if="step < 3" src="../assets/fail.png" />
        <p>报名成功</p>
      </van-col>
    </van-row>
    <!-- <div :class="$style.container">
      <div>
        <img v-if="step < 1" src="../assets/fail.png" />
        <img v-if="step === 1" src="../assets/step2.png" />
        <img v-if="step > 1" src="../assets/success.png" />
      </div>
      <div v-if="questionnaireId !== ''" :class="$style.line"></div>
      <div v-if="questionnaireId !== ''">
        <img v-if="step < 2" src="../assets/fail.png" />
        <img v-if="step === 2" src="../assets/step2.png" />
        <img v-if="step > 2" src="../assets/success.png" />
      </div>
      <div :class="$style.line"></div>
      <div>
        <img v-if="step >= 3" src="../assets/success.png" />
        <img v-if="step < 3" src="../assets/fail.png" />
      </div>
    </div>
    <div :class="$style.text">
      <span>个人信息</span>
      <span v-if="questionnaireId !== ''">填写问卷</span>
      <span>报名成功</span>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["step"],
  computed: {
    ...mapState({
      questionnaireId: (state) => state.application.properties.questionnaireId,
    }),
  },
};
</script>

<style lang="less" module>
.container {
  display: flex;
  font-size: 13px;
  padding: 15px 15px 5px 15px;
  align-items: flex-start;
  img {
    height: 30px;
    width: 30px;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .line {
    margin: 12px 6px;
    width: 103px;
    height: 5px;
    background: #314575;
  }
}
.text {
  display: flex;
  font-size: 13px;
  padding: 0 55px;
  justify-content: space-between;
  align-items: center;
}
</style>